'use client';

import { Icon } from '@components';

import classes from './Features.module.scss';

interface IFeatureItem {
  icon: 'potted' | 'discount' | 'delivery' | 'support';
  title: string;
}

const featuresList: IFeatureItem[] = [
  {
    icon: 'potted',
    title: 'Найбільший асортимент кімнатних рослин в Україні'
  },
  {
    icon: 'discount',
    title: 'Постійні акційні пропозиції'
  },
  {
    icon: 'delivery',
    title: 'Доставка день в день'
  },
  {
    icon: 'support',
    title: 'Онлайн супровід на всіх етапах'
  }
];

const MainFeatures = () => {
  return (
    <article className={classes.root}>
      <div className={classes.featuresContainer}>
        {featuresList.map((item, index) => (
          <div key={index} className={classes.featuresItem}>
            <figure className={classes.featureIconContainer}>
              <Icon className={classes.featureIcon} type={item.icon} offset="auto" leftOffset="auto" />
            </figure>
            <h4 className={classes.featuresItemTitle}>{item.title}</h4>
          </div>
        ))}
      </div>
    </article>
  );
};

export default MainFeatures;
