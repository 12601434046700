import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/components/sections/offers/Offers.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/shared/meta-links/MetaLinks.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/shared/meta-text/MetaText.module.scss");
import(/* webpackMode: "eager" */ "/app/src/layout/footer/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/layout/header-main/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/features/Features.tsx");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/Main.module.scss");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/FeaturedCategories/FeaturedCategories.module.scss");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/top/MainTop.tsx")